import {
  BannerCardV3,
  ERichTextBullets,
  toEnum,
  toImageProps,
  toRichText,
} from '@front/shared/ds';
import React, { useMemo } from 'react';

import type { Section } from '@shared/master-types';

type TUsePreparedDataProps = Extract<
  Required<Section>['rows'][number],
  { blockType: 'banner-card-v3' }
>;

export const usePreparedData = (props: TUsePreparedDataProps) => {
  const { tabs = [] } = props;

  return useMemo(
    () =>
      tabs.map(({ card, richTitle }) => ({
        title: toRichText(richTitle, { hasWrapper: false }),
        card: {
          title: toRichText(card?.title, { hasWrapper: false }),
          subTitle: toRichText(card?.subTitle, { hasWrapper: false }),
          description: toRichText(card?.description, {
            hasWrapper: false,
            bulletsOptions: {
              bullets: ERichTextBullets.Custom,
            },
            classNames: {
              li: 'before:bg-brand-500 icon-checkmark-2 before:size-6 before:-start-[2.5rem] before:top-0',
              ul: 'ps-[2.5rem]',
            },
          }),
          image: toImageProps(card?.image) || undefined,
          imageMobile: toImageProps(card?.imageMobile) || undefined,
          icon: toImageProps(card?.icon) || undefined,
        },
      })),
    [tabs],
  );
};
