import { BannerCardV3, Container, EContainerVariant } from '@front/shared/ds';
import React from 'react';

import type { Section } from '@shared/master-types';
import { usePreparedData } from './usePreparedData';

export type THorizontalCardsV4ConnectedProps = Extract<
  Required<Section>['rows'][number],
  { blockType: 'banner-card-v3' }
>;

const BannerCardV3Connected: React.FC<THorizontalCardsV4ConnectedProps> = ({
  gradientColorFrom,
  gradientColorTo,
  ...rest
}) => {
  const preparedTabs = usePreparedData(rest);

  return (
    <BannerCardV3
      tabs={preparedTabs}
      gradientColorFrom={gradientColorFrom}
      gradientColorTo={gradientColorTo}
    />
  );
};

export default BannerCardV3Connected;
