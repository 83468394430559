import { ESimpleIconSize, Icon, ImageWithPlaceholder } from '@front/shared/ds';
import React from 'react';
import clsx from 'clsx';
import { ImageProps } from 'next/image';

import { Description } from './Description';

type TCardProps = {
  title?: React.JSX.Element | string;
  subTitle?: React.JSX.Element | string;
  description?: React.JSX.Element | string;
  image?: ImageProps;
  imageMobile?: ImageProps;
  icon?: ImageProps;
};

export const Card: React.FC<TCardProps> = ({
  title,
  subTitle,
  description,
  image,
  imageMobile,
  icon,
}) => {
  return (
    <div
      className={clsx(
        'flex h-full flex-col gap-8 overflow-hidden tablet:h-auto tablet:flex-row desktop:gap-10',
      )}
    >
      <div className='flex flex-1 flex-col gap-6 tablet:min-w-[280px] tablet:basis-[35%] tablet:pb-[72px] desktop:min-w-[360px]'>
        {icon ? (
          <div>
            <Icon icon={icon} size={ESimpleIconSize.XxL} />
          </div>
        ) : null}

        {title ? (
          <div className={clsx('text-4xl font-semibold leading-relaxed')}>
            {title}
          </div>
        ) : null}

        {subTitle ? (
          <div className={clsx('text-xl font-semibold leading-extra-loose')}>
            {subTitle}
          </div>
        ) : null}

        <Description description={description} />
      </div>

      <div className={clsx('mt-auto flex flex-1 items-end tablet:basis-[65%]')}>
        {image ? (
          <ImageWithPlaceholder
            {...image}
            className='hidden max-w-none grow tablet:block tablet:h-[512px] tablet:w-auto'
          />
        ) : null}
        {imageMobile ? (
          <ImageWithPlaceholder
            {...imageMobile}
            className='h-auto w-full tablet:hidden'
          />
        ) : null}
      </div>
    </div>
  );
};
