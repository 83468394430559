import { Gallery } from '@front/shared/ds';
import React from 'react';
import { ImageProps } from 'next/image';

import { SwiperSlide } from 'swiper/react';
import { Card } from './components/Card';
import { EGalleryGap } from '../Gallery/Gallery';

type TBannerCardV3Props = {
  tabs: {
    title?: React.JSX.Element | string;
    card: {
      title?: React.JSX.Element | string;
      subTitle?: React.JSX.Element | string;
      description?: React.JSX.Element | string;
      image?: ImageProps;
      icon?: ImageProps;
    };
  }[];
  gradientColorFrom?: string;
  gradientColorTo?: string;
};

export const BannerCardV3: React.FC<TBannerCardV3Props> = ({
  tabs = [],
  gradientColorFrom,
  gradientColorTo,
}) => {
  return (
    <Gallery
      gradientColorFrom={gradientColorFrom}
      gradientColorTo={gradientColorTo}
      nowrapForTabs={false}
      tabs={tabs}
      navigationClass='bg-white'
      gap={EGalleryGap.Gap72}
    >
      {tabs.map(({ card }, index) => (
        <SwiperSlide className='h-auto' key={index}>
          <Card {...card} />
        </SwiperSlide>
      ))}
    </Gallery>
  );
};
